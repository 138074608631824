/**************************************************************
                # intro section start here
**************************************************************/

#intro {
    width: 100%;
    height: 100vh;
    background: url('https://raw.githubusercontent.com/alokknight/library_cdn/master/AdarshLibrary/Images/meeting.jpg') top center;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

@media (min-width: 1024px) {
    #intro {
        background-attachment: fixed;
    }
}

#intro:before {
    content: "";
    background: rgba(13, 15, 31, 0.8);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#intro .intro-container {
    position: absolute;
    height: 450px;
    bottom: 0;
    left: 0;
    top: 90px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 15px;
}

@media (max-width: 991px) {
    #intro .intro-container {
        top: 70px;
    }
}

#intro  h1 {
    color: #fff;
    font-family: "Raleway", sans-serif;
    font-size: 56px;
    font-weight: 600;
    text-transform: uppercase;
}

#intro h1 span {
    color: #cbb162;
}

@media (max-width: 991px) {
    #intro h1 {
        font-size: 34px;
    }
}

#intro p {
    color: #ebebeb;
    font-weight: 700;
    font-size: 20px;
}

@media (max-width: 991px) {
    #intro p {
        font-size: 16px;
    }
}

/* #intro */
.play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#f82249 50%, rgba(101, 111, 150, 0.15) 52%);
    border-radius: 50%;
    display: block;
    position: relative;
    overflow: hidden;
}

/* #intro */
.play-btn::after {
    content: 'add';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* #intro */
.play-btn:before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 1.5s;
    animation: pulsate-btn 1.5s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 2px solid rgba(163, 163, 163, 0.4);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
}

/* #intro */
.play-btn:hover::after {
    border-left: 15px solid #f82249;
    transform: scale(20);
}

/* #intro */
.play-btn:hover::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
}

/* #intro */
.about-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #f82249;
}

#intro
.about-btn:hover {
    background: #f82249;
    color: #fff;
}

@-webkit-keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes pulsate-btn {
    0% {
        transform: scale(0.6, 0.6);
        opacity: 1;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

/**************************************************************
                intro end here
**************************************************************/