/* scrollbarStyles.css */
.scrollContainer {
    overflow-x: auto;
    padding: 15px;
  }

  /* Style the scrollbar track */
  .scrollContainer::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    height: 12px; /* Height of the scrollbar for horizontal scrollbars */
  }

  /* Style the scrollbar thumb (the draggable part) */
  .scrollContainer::-webkit-scrollbar-thumb {
    background: #000000; /* Black color */
    border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  }

  /* Style the scrollbar track (part that the thumb moves along) */
  .scrollContainer::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey color */
  }

  /* Optional: Style the scrollbar buttons (arrows) */
  .scrollContainer::-webkit-scrollbar-button {
    background: #5a0404; /* Black color for the buttons */
    border-radius: 10px; /* Rounded corners for the buttons */
  }

  /* Optional: Hover effect for the scrollbar thumb */
  .scrollContainer::-webkit-scrollbar-thumb:hover {
    background: #555; /* Slightly lighter grey when hovering */
  }
